import { useMediaQuery } from "react-responsive";
import { ProductsContainer, ProductsText, FlexDiv } from "../../styles";
import { PRODUCTS } from "../../constants/text";
import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ResponsivePagination from "react-responsive-pagination";
import img1 from "../../assets/img1.jpg";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckToken, GetProduct } from "../../Clients/request";

const ITEMS_PER_PAGE = 10;

const Products = () => {
  const [List, setList] = useState();
  const [search, setSearch] = useState("");
  const isDesktopHeight = useMediaQuery({ minHeight: 870 });
  const isLaptopWidth = useMediaQuery({ minWidth: 580 });
  const isMediumScreen = useMediaQuery({ minWidth: "1000px" });
  const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
  const isProductDesktopView = useMediaQuery({ minWidth: 1200 });
  const isProductLaptopView = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isProductMobileView = useMediaQuery({ maxWidth: 768 });
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState([]);
  const [sum, setSum] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetProduct(localStorage.getItem("token")).then((res) => {
      if (res.data) {
        if (res.data.status === "200") {
          const prod = res.products.map((product) => {
            const sizes = product.size.split("x");
            const dimension = sizes.slice(0, 2).join("x");
            const size = sizes[2];
            return {
              ...product,
              dimension: dimension,
              size: size,
            };
          });
          setList(prod);
          setLoading(false);
        } else {
          console.log("Bad response");
        }
      } else {
        console.log("error");
      }
    });
  }, []);

  useEffect(() => {
    if (List) {
      setCount(new Array(List.length).fill(0));
    }
  }, [List]);

  const navigate = useNavigate();

  const searchList =
    List &&
    List.filter((item) => {
      const searchString = search !== "" ? search?.toLowerCase() : "";
      console.log("it", item);
      return (
        item.type?.toLowerCase().includes(searchString) ||
        item.dimension.includes(searchString) ||
        item.thickness.includes(searchString)
        
      );
    });

  const handleSubmitClick = () => {
    if (filteredList.length > 0) {
      console.log("dt", filteredList);
      navigate("/Cart", { state: { cartData: filteredList } });
    } else {
      setOpen(true);
    }
  };
  const addQuantityToProducts = () => {
    const ret = List
      ? List.map((product, index) => ({
          ...product,
          order_quantity: count ? count[index] : 0,
        }))
      : "";
    return ret;
  };

  const filteredList = List
    ? addQuantityToProducts()?.filter((product) => product.order_quantity > 0)
    : "";

  const handleIncrement = (index) => {
    setCount((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = prevCounts[index] + 1;
      return newCounts;
    });
  };

  const handleDecrement = (index) => {
    if (count[index] > 0) {
      setCount((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = prevCounts[index] - 1;
        return newCounts;
      });
    }
  };

  const handleInputChange = (event, index) => {
    const value = parseInt(event.target.value);

    if (!isNaN(value)) {
      setCount((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = value;
        return newCounts;
      });
    }
  };

  const Quantity_Button = ({ index }) => {
    return (
      <div style={{ width: "80px" }}>
        <div
          style={{
            display: "flex",
            border: "1px solid gray",
            borderRadius: "7px",
            maxWidth: "200px",
            justifyContent: "space-between",
          }}
        >
          <div onClick={() => handleDecrement(index)}>
            <RemoveIcon style={{ color: "grey" }} />
          </div>
          <input
            type="text"
            value={count[index]}
            onChange={(event) => handleInputChange(event, index)}
            style={{ maxWidth: "30px", textAlign: "center" }}
          />
          <div className="pl-0" onClick={() => handleIncrement(index)}>
            <AddIcon style={{ color: "grey" }} />
          </div>
        </div>
      </div>
    );
  };

  const total_items = count?.reduce((acc, item) => acc + item, 0);

  useEffect(() => {
    if (List) {
      const newSum = List.reduce((acc, item, index) => {
        return acc + item.price * (count[index] || 0);
      }, 0);
      setSum(newSum);
    }
  }, [total_items]);

  useEffect(() => {
    const Token = localStorage.getItem("token");
    if (!Token) {
      navigate("/login");
    } else {
      CheckToken(Token).then((res) => {
        console.log("res", res);
        if (res.data) {
          if (res.data.status == 202) {
            console.log("good response");
          } else {
            console.log("Bad response");
            navigate("/login");
          }
        } else {
          console.log("error");
          navigate("/login");
        }
      });
    }
  }, []);

  const changePage = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const renderTableData = () => {
    if (search !== "") {
      const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIdx = startIdx + ITEMS_PER_PAGE;
      return searchList ? searchList.slice(startIdx, endIdx) : [];
    } else {
      const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIdx = startIdx + ITEMS_PER_PAGE;
      return List ? List.slice(startIdx, endIdx) : [];
    }
  };

  useEffect(() => {
    console.log("s", search);
  }, [search]);

  return (
    <div style={{ height: "100vh", overflowY: "hidden" }}>
      <Navbar />
      {loading ? (
        <LinearProgress
          color="success"
          style={{ width: "100%", marginTop: "5px" }}
        />
      ) : (
        <div
          className="container d-flex justify-content-center"
          style={{
            marginTop: isMaxDesktop ? "1rem" : ".5rem",
            marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
          }}
        >
          <ProductsContainer>
            <FlexDiv
              style={{
                justifyContent: "space-between",
                padding: "2rem 3rem 0.5rem 3rem",
                flexDirection: isLaptopWidth ? "row" : "column",
                alignItems: isLaptopWidth ? "" : "center",
              }}
            >
              <ProductsText>{PRODUCTS}</ProductsText>
            </FlexDiv>

            <div
              style={{
                overflow: "auto",
                padding: "0.2rem 1.5rem 1rem 1.5rem",
              }}
            >
              <div style={{ width: "40%"}}>
                <TextField
                  fullWidth
                  color="success"
                  label="Search for products"
                  variant="outlined"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon color="action" style={{ marginRight: 8 }} />
                    ),
                    style: { marginBottom: 16, borderRadius: "10px" },
                  }}
                />
              </div>
              <TableContainer
                component={Paper}
                style={{
                  minWidth: "800px",
                  // padding: "20px",
                  minHeight: "40vh",
                  borderRadius: "24px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  maxHeight:"50vh",overflow:"auto"
                }}
              >
                <Table aria-label="customized table" stickyHeader>
                  <TableHead >
                    <TableRow>
                      <TableCell style={{ width: "20%", textAlign: "center" }}>
                        Type
                      </TableCell>
                      <TableCell style={{ width: "12.5%", textAlign: "left" }}>
                        Dimension
                      </TableCell>
                      <TableCell style={{ width: "12.5%", textAlign: "left" }}>
                        Thickness
                      </TableCell>
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        Price per m<sup>2</sup>
                      </TableCell>
                      <TableCell
                        style={{ width: "12.5%", textAlign: "center" }}
                      >
                        Boards for Pallets
                      </TableCell>
                      <TableCell
                        style={{
                          width: "17.5%",
                          textAlign: "center",
                        }}
                      >
                        Quantity
                      </TableCell>
                      <TableCell style={{ width: "15%", textAlign: "center" }}>
                        Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderTableData().map((row, index) => (
                      <TableRow key={row.Id}>
                        <TableCell component="th" scope="row">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "50px",
                            }}
                          >
                            {isMediumScreen && (
                              <img
                                src={row.url}
                                style={{
                                  width: "50px",
                                  maxHeight: " 50px",
                                  borderRadius: "10px",
                                  marginRight: "20px",
                                }}
                              />
                            )}
                            {row.type}
                          </div>
                        </TableCell>
                        <TableCell align="left">{row.dimension}mm</TableCell>
                        <TableCell align="left">{row.thickness}mm</TableCell>
                        <TableCell align="center">€ {row.ppsq}</TableCell>
                        <TableCell align="center">{row.btmp}</TableCell>
                        <TableCell style={{ paddingLeft: "70px" }}>
                          <Quantity_Button index={index} />
                        </TableCell>
                        <TableCell align="center">€ {row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      padding: 1,
                      paddingX: 4,
                      margin: 2,
                      marginX: 5,
                      minWidth: "100px",
                      backgroundColor: "#4caf4d",
                      borderRadius: "21px",
                    }}
                    onClick={handleSubmitClick}
                  >
                    Bestellen
                  </Button>

                  <p style={{ marginLeft: "auto" }}>
                    Subtotal &nbsp;
                    <span style={{ fontWeight: "bold" }}>
                      {total_items} Items
                    </span>
                  </p>

                  <p
                    style={{ paddingLeft: total_items < 10 ? "10%" : total_items < 100 ? "9%" : "8%",marginRight:"40px" }}

                  >
                    €<span>{sum}</span>
                    /-
                  </p>
                </div>
            </div>
            <div style={{ margin: "auto", paddingBottom: "10px" }}>
              <ResponsivePagination
                current={currentPage}
                total={
                  search === ""
                    ? Math.ceil(List?.length / ITEMS_PER_PAGE)
                    : Math.ceil(searchList?.length / ITEMS_PER_PAGE)
                }
                onPageChange={changePage}
              />
            </div>
          </ProductsContainer>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message="Please select a product first"
      />
    </div>
  );
};
export default Products;
