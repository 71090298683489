import { ProductsContainer,OrdersText, StyledRangePickerContainer } from "../../styles";
import { useState } from "react";
import Navbar from "../../components/Navbar";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { CheckToken } from "../../Clients/request";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { DatePicker } from "antd";
import "./Summary.css";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { GetUserInformation } from "../../Clients/request";

const { RangePicker } = DatePicker;
ChartJS.defaults.color = "black";

const LineChart = ({orders,selectedItem}) => {
  const dt = () => {
    const temp = [0, 0, 0, 0]
    
    const currentMonth = new Date().getMonth(); //get current month

    if(selectedItem == 0){ //orders price
      orders && Object.values(orders).forEach((order) => {
        const month = new Date(order.created_at).getMonth()
        const monthDifference = (currentMonth - month +12 ) % 12;
        if(monthDifference >= 0 && monthDifference < 4){
          let sum = 0;
          if(order.amount.includes(',')){
            order.amount.split(',').forEach(num => {sum += parseFloat(num)})
            temp[3 - monthDifference] += parseFloat(sum)
          } else {
            temp[3 - monthDifference] += parseFloat(order.amount)
          }
        }
      })
    }
    else if (selectedItem == 1){//order transactions
      orders && Object.values(orders).forEach((order) => {
        const month = new Date(order.created_at).getMonth()
        const monthDifference = (currentMonth - month +12 ) % 12;
        if(monthDifference >= 0 && monthDifference < 4){
          temp[3 - monthDifference] += 1
        }
      })
    }
    else { // amount of boards
      orders && Object.values(orders).forEach((order) => {
        const month = new Date(order.created_at).getMonth()
        const monthDifference = (currentMonth - month +12 ) % 12;
        if(monthDifference >= 0 && monthDifference < 4){
          let sum = 0;
          if(order.user_order_quantity.includes(',')){
            order.user_order_quantity.split(',').forEach(num => {sum += parseFloat(num)})
            temp[3 - monthDifference] += parseInt(sum)
          } else {
            temp[3 - monthDifference] += parseInt(order.user_order_quantity)
          }
        }
      })
    }
    return temp
  }
  
  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month];
  };

  const labels = Array.from({ length: 4 }, (_, index) => {
    const monthIndex = (new Date().getMonth() - index + 12) % 12;
    return getMonthName(monthIndex);
  }).reverse();

  const data = {
    labels: labels,
    datasets: [
      {
        label: selectedItem == 0 ? "Monthly Price" : selectedItem == 1 ? "Monthly Transaction" : "Monthly Order Quantity",
        data: dt(),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Set display to false to hide the legend
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} style={{marginTop:"0px",marginBottom:"10px",width:"800px",maxHeight:"300px"}}/>
    </div>
  );
};

const Vertical_Nav = {
  Insights: ["Summary"],
  // Statements: ["entry1", "entry2"],
  // "Financial Summaries": [],
};

const Reports = () => {
  const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [data,setData] = useState("");
  const [orders,setOrders] = useState();
  const [ordersTemp,setOrdersTemp] = useState();

  useEffect(() => {
    const Token = localStorage.getItem("token");
    if (!Token) {
      navigate("/login");
    } else {
      CheckToken(Token).then((res) => {
        console.log("res", res);
        if (res.data) {
          if (res.data.status == 202) {
            console.log("good response");
          } else {
            console.log("Bad response");
            navigate("/login");
          }
        } else {
          console.log("error");
          navigate("/login");
        }
      });
    }
  }, []);

  useEffect(() => {
    GetUserInformation(localStorage.getItem('token')).then(res => {
      if(res.data.status == 200){
        setData({count : res.count, totalAmount : res.totalAmount , totalquantity : res.totalquantity})
        setOrders(res.order)
        setOrdersTemp(res.order)
        setLoading(false)
      }
    })
    
  }, [])

  useEffect(() => {
    if(orders && orders.length == 0 ){
      setOrders(ordersTemp);
    }
  }, [orders])

  useEffect(() => {
    const filtered = orders && value && Object.values(orders).filter((order) => {
      const date1 = new Date(value[0])
      const date2 = new Date(value[1])
      const orderDate = new Date(order.created_at.split("T")[0])
      return orderDate >= date1 && orderDate <= date2
    })
    setOrders(filtered)
    console.log("orders",filtered)
  }, [value])
  

  const handleGridItemClick = (index) => {
    setSelectedItem(index);
  };

  const disabledDate = (current) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Calculate the date three months ago from the current date
    const threeMonthsAgo = new Date(currentYear, currentMonth - 3, 1);
  
    // Disable dates from current month onwards and three months back
    return current && (current > currentDate || current < threeMonthsAgo);
  };

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <Navbar />
      {loading ? (
        <LinearProgress
          color="success"
          style={{ width: "100%", marginTop: "5px" }}
        />
      ) : (
      <div
        className="container d-flex justify-content-center"
        style={{
          // height: isMaxDesktop ? "84vh" : "80vh",
          // overflowY: "auto",
          marginTop: isMaxDesktop ? "1rem" : ".5rem",
          //   marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
        }}
      >
        <ProductsContainer>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "2rem 3rem 0rem 3rem",
              }}
            >
              <OrdersText>Summary</OrdersText>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "24px",
                margin: "10px 30px 10px 30px",
                paddingTop: "40px",
                paddingLeft: "40px",
                paddingRight: "20px",
                minHeight: "70vh",
              }}
            >
              <RangePicker
                value={dates || value}
                onCalendarChange={(val) => {
                  console.log(val);
                  setDates(val);
                }}
                onChange={(date, dateArray) => {
                  console.log(date);
                  console.log(dateArray);
                  setValue(dateArray);
                }}
                changeOnBlur
                panelRender={(panelNode) => (
                  <StyledRangePickerContainer>
                    {panelNode}
                  </StyledRangePickerContainer>
                )}
                disabledDate={disabledDate}
              />

              <Grid container spacing={0}>
                <Grid item md={4} sm={6} xs={10}>
                  <div
                    className={`grid-item ${
                      selectedItem === 0 ? "selected" : "" // Apply "selected" class based on the index
                    }`}
                    onClick={() => handleGridItemClick(0)}
                  >
                    <h1 className="orders" style={{ fontSize: "16px" }}>
                      Orders Price
                    </h1>
                    <Grid container>
                      <Grid item>
                        <h1 style={{ fontSize: "16px", marginTop: "5px" }}>
                          €
                        </h1>
                      </Grid>
                      <Grid item>
                        <h1
                          className="amount"
                          style={{ marginTop: "0px", marginLeft: "5px" }}
                        >
                          {data?.totalAmount?.toFixed(2)}
                        </h1>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={10}>
                  <div
                    className={`grid-item ${
                      selectedItem === 1 ? "selected" : "" // Apply "selected" class based on the index
                    }`}
                    onClick={() => handleGridItemClick(1)}
                  >
                    <h1 className="orders" style={{ fontSize: "16px" }}>
                      Transactions
                    </h1>
                    <h1 className="amount">{data.count}</h1>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={10}>
                  <div
                    className={`grid-item ${
                      selectedItem === 2 ? "selected" : "" // Apply "selected" class based on the index
                    }`}
                    onClick={() => handleGridItemClick(2)}
                  >
                    <h1 className="orders" style={{ fontSize: "16px" }}>
                      Amount of Boards
                    </h1>
                    <h1 className="amount">{data.totalquantity}</h1>
                  </div>
                </Grid>
              </Grid>
              <LineChart orders={orders} selectedItem={selectedItem} />
            </div>
          </div>
        </ProductsContainer>
      </div>
      )}
    </div>
  );
};

export default Reports;