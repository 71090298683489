import Dashboard from "../views/Dashboard";
import Orders from "../views/Orders";
import Products from "../views/Products";
import Reports from "../views/Reports/Reports";
import Login from "../views/Login";
import Profile from "../views/Profile";
import Order from "../views/Products/Order"
import { Routes, Route } from "react-router-dom";

const Router = () => (
    <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/" element={<Dashboard />}/>
        <Route path="/orders" element={<Orders />} />
        <Route path="/products" element={<Products />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Cart" element={<Order />} />
    </Routes>
)
export default Router;