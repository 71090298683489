import { NavigationBar, NavText, FlexDiv, NavButtons, Sidebar, SidebarButtons, SidebarText } from "../styles.js";
import cornucopia_landing_icon from "../assets/cornucopia_landing_icon.png";
import notify_icon from "../assets/notification_icon.svg";
import { useMediaQuery } from 'react-responsive'
import { DASBOARD, ORDERS, REPORTS, PRODUCTS, HELP , MY_PROFILE, NOTIFICATIONS , CLOSE} from "../constants/text";
import {SlMenu} from "react-icons/sl";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { IconButton } from "@mui/material";
import { useSelector } from 'react-redux'
import { CheckToken } from "../Clients/request.js";
import logo from "../assets/logo.jpeg"


const NAVBAR_URL_AND_STATUS = {
  "dashboard" : ['/' , true], 
  "orders" : ['/orders' , false], 
  "products" : ['/products' , false],
  "reports" : ['/reports' , false], 
  "profile" : ['/profile', false] 
};

const NavBar = () => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isLaptop = useMediaQuery({ minWidth: 1000 });
    const [openSidebar, setOpenSidebar] = useState(false);
    const [anchor, setAnchor] = useState();
    const navigate = useNavigate();
    const currentUrl = window.location.href.split("/");
    const user = useSelector((state) => state.auth)

    const handleClick = (event) => {
      setAnchor(event.currentTarget);
    };
    const handleClose = () => {
      setAnchor(null);
    };
    const handlelogout = () => {
      localStorage.removeItem("token");
      setAnchor(null);
      navigate("/login");
    };
    const handleSetting = () => {
      changePage("profile");
      setAnchor(null);
    };

    const changePage = (key) => {
      navigate(NAVBAR_URL_AND_STATUS[key][0]);
    }

    const updateStatus = (key) => {
      return (currentUrl[currentUrl.length - 1] === key);
    }

    useEffect(() => {
      const Token = localStorage.getItem("token");
      if(!Token){
          navigate("/login");
      }
      else {
          CheckToken(Token).then((res) => {
              console.log("tokenchecked",res)
              if (res.data) {
                  if (res.data.status == 202) {
                      console.log("good response");
                  } else {
                      console.log("Bad response");
                      navigate("/login")
                  }
              } else {
                  console.log("error");
              }
          })
      }
  }, [])
    return (
        <>    
        <NavigationBar style={{justifyContent:"space-between"}}>
          { isLaptop ? 
            <>
              <FlexDiv  style={{height:"100%"}}>
              <img src={logo} alt="img"/>
              <FlexDiv style={{paddingLeft:"5rem", height:"100%"}}>
                <NavText style={{borderBottom: updateStatus('') ? "0.2rem solid #009580" : ""}}><NavButtons onClick={() => changePage("dashboard")}><NavText>{DASBOARD}</NavText></NavButtons></NavText>
                <NavText style={{borderBottom: updateStatus("orders") ? "0.2rem solid #009580" : ""  , marginLeft:isDesktop ? "4rem" : "2rem"}}><NavButtons onClick={() => changePage("orders")}><NavText>{ORDERS}</NavText></NavButtons></NavText>
                <NavText style={{borderBottom: updateStatus("products") ? "0.2rem solid #009580" : ""  ,marginLeft:isDesktop ? "4rem" : "2rem"}}><NavButtons onClick={() => changePage("products")}><NavText>{PRODUCTS}</NavText></NavButtons></NavText>
                <NavText style={{borderBottom: updateStatus("reports") ? "0.2rem solid #009580" : ""  ,marginLeft:isDesktop ? "4rem" : "2rem"}}><NavButtons onClick={() => changePage("reports")}><NavText>{REPORTS}</NavText></NavButtons></NavText>
              </FlexDiv>
              </FlexDiv>
              <FlexDiv style={{height:"100%"}}>
              <NavText style={{paddingRight: isDesktop ? "4rem" : "1rem" }}><NavButtons><a href="mailto:help@magoxx.com">{HELP}</a></NavButtons></NavText>
              <NavText style={{borderBottom: updateStatus("profile") ? "0.2rem solid #009580" : ""  ,marginRight:"1.5rem"}}><NavButtons ><NavText>{user.name ? user.name.split(" ")[0] : ''}</NavText></NavButtons></NavText>
                <div style={{width:"32px", height: "32px", borderRadius:"16px", border: "1px solid #ececec", marginRight:"0.7rem", marginTop:"1.5rem"}}>      
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: -0.8, mt : -0.8}}
                  aria-controls={Boolean(anchor) ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchor) ? 'true' : undefined}
                >
                  <Avatar sx={{ width: "32px", height: "32px" }}>{user.name ? user.name[0] : ''}</Avatar>
                </IconButton>
                <Menu
                  anchorEl={anchor}
                  id="account-menu"
                  open={Boolean(anchor)}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleSetting}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handlelogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                </div>
              </FlexDiv>
            </>
          :
          <>
            <h1 style={{fontSize:"35px",color:"green",fontWeight:"bold",paddingTop:"10px"}}>Magoxx</h1>
            <SlMenu
              style={{ cursor: "pointer" }}
              onClick={() => {setOpenSidebar(openSidebar ? false : true)}}
            />
            <Sidebar style={{width: openSidebar ? "300px" : "0px"}}>
              { openSidebar ? 
                <>
                  <SidebarText><SidebarButtons onClick={() => {setOpenSidebar(false)}}><NavText>{CLOSE}</NavText></SidebarButtons></SidebarText>
                  <SidebarText><SidebarButtons><NavText>{MY_PROFILE}</NavText></SidebarButtons></SidebarText>
                  <SidebarText><SidebarButtons onClick={() => changePage("dashboard")}><NavText>{DASBOARD}</NavText></SidebarButtons></SidebarText>
                  <SidebarText><SidebarButtons onClick={() => changePage("orders")}><NavText>{ORDERS}</NavText></SidebarButtons></SidebarText>
                  <SidebarText><SidebarButtons onClick={() => changePage("reports")}><NavText>{REPORTS}</NavText></SidebarButtons></SidebarText>
                  <SidebarText><SidebarButtons onClick={() => changePage("products")}><NavText>{PRODUCTS}</NavText></SidebarButtons></SidebarText>
                </>
                :
                <></>
              }    
            </Sidebar>
          </>
        }
        </NavigationBar>
      </>
    );
}
export default NavBar;
