import {
  OrderContainer,
  OrdersText,
  FlexDiv,
  AddOrderBtn,
  InnerOrderDiv,
  PendingOrderText,
  TableHeadText,
  TableTextOne,
  TableTextFour,
  TableTextTwo,
  TableTextThree,
  SortImg,
  GreenLine,
  StyledRangePickerContainer,
} from "../styles";
import { useMediaQuery } from "react-responsive";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  NEW_ORDERS,
  PENDING_ORDERS,
  ORDERS,
  ORDER,
  DATE_ORDER,
  ARRIVAL_DATE,
  BOARDS,
  STATUS,
} from "../constants/text";
import Checkbox from "@mui/material/Checkbox";
import Sort from "../assets/sort.png";
import OrderDropdown from "../components/OrderDropdown";
import { ORDER_DATA, STATUS_COLORS } from "../constants/OrdersData";
import ResponsivePagination from "react-responsive-pagination";
import Navbar from "../components/Navbar";
import { CheckToken, GetOrders } from "../Clients/request";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from "@mui/material";

const { RangePicker } = DatePicker;

const columns = [
  { 
    field: 'id', 
    headerName: 'Order', 
    width: 150 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextFour>
          {params.row.order_id}
        </TableTextFour>
        <TableTextOne>
          {params.row.name}
        </TableTextOne>
      </div>
    )
  },
  { 
    field: 'updated_at', 
    headerName: 'Date Order', 
    width: 250 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextTwo>
          {new Date(params.row.updated_at).toISOString().slice(0, 10)}
        </TableTextTwo>
        <TableTextThree>
          {new Date(params.row.updated_at).toISOString().slice(11, 19)}
        </TableTextThree>
      </div>
    )
  },
  { 
    field: 'deliverydate', 
    headerName: 'Arrival Order', 
    width: 300 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextTwo>
          {params.row.deliverydate}
        </TableTextTwo>
        <TableTextThree>
          {params.row.deliverydate}
        </TableTextThree>
      </div>
    )
  },
  {
    field: 'products_type',
    headerName: 'Boards',
    width: 300,
    headerAlign:"center",
    align:"center",
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    headerAlign:"center",
    align:"center",
    renderCell: (params) => (
      <div style={{ color: `${STATUS_COLORS[params.row.status]}` }}>
        {params.row.status}
      </div>
    )
  }
];


const Orders = () => {
  const isDesktopHeight = useMediaQuery({ minHeight: 870 });
  const isLaptopWidth = useMediaQuery({ minWidth: 580 });
  const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const [userOrders,setUserOrders] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const Token = localStorage.getItem("token");
      if(!Token){
          navigate("/login");
      }
      else {
          CheckToken(Token).then((res) => {
              // console.log("res",res)
              if (res.data) {
                  if (res.data.status == 202) {
                      console.log("Token authenticated");
                  } else {
                      console.log("Bad response");
                      navigate("/login")
                  }
              } else {
                  console.log("error");
                  navigate("/login")
              }
          })
          GetOrders(Token).then((res) => {
            console.log("orders",res)
            if (res.data) {
              if (res.data.status === "200") {
                const rows = Object.values(res.order).map((order) => (order) )
                setUserOrders(rows);
                setLoading(false)
              } else {
                  console.log("Bad response");
              }
          } else {
              console.log("error");
          }
          })
      }
  }, [])
  

  return (
    <div style={{height:"100vh",overflowY:"auto"}}>
      <Navbar />
      {loading ? (<LinearProgress color="success" style={{width:"100%",marginTop:"5px"}}/>) : (
      <div
        className="container d-flex justify-content-center"
        style={{
          // height: isMaxDesktop ? "84vh" : "80vh",
          marginTop: isMaxDesktop ? "1rem" : ".5rem",
            marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
        }}
      >
        <OrderContainer>
          <FlexDiv
            style={{
              justifyContent: "space-between",
              padding: "2rem 3rem 1rem 3rem",
              flexDirection: isLaptopWidth ? "row" : "column",
              alignItems: isLaptopWidth ? "" : "center",
            }}
          >
            <OrdersText>{ORDERS}</OrdersText>
            <button style={{ border: "none", background: "transparent" }} onClick={() => navigate('/products  ')}>
              <AddOrderBtn>+ New Order</AddOrderBtn>
            </button>
          </FlexDiv>
          <FlexDiv style={{ margin: "0rem 1rem 0rem 1rem" }}>
            <InnerOrderDiv style={{marginBottom:"20px"}}>
              <FlexDiv
                style={{
                  justifyContent: "space-between",
                  margin: "2rem 1.5rem",
                  flexDirection: isLaptopWidth ? "row" : "column",
                  alignItems: isLaptopWidth ? "" : "center",
                }}
              >
                <PendingOrderText>{PENDING_ORDERS}</PendingOrderText>
                <div>
                  <RangePicker
                    value={dates || value}
                    onCalendarChange={(val) => {
                      console.log(val);
                      setDates(val);
                    }}
                    onChange={(date, dateArray) => {
                      console.log(date);
                      console.log(dateArray);
                      setValue(dateArray);
                    }}
                    changeOnBlur
                    panelRender={(panelNode) => (
                      <StyledRangePickerContainer>
                        {panelNode}
                      </StyledRangePickerContainer>
                    )}
                  />
                </div>
              </FlexDiv>
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  padding: "0.5rem 1.5rem 1.5rem 1.5rem",
                }}
              >
               <div>
                  <DataGrid
                    autoHeight
                    rows={userOrders}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5,10,20]}
                  />
                
                </div>
              </div>
            </InnerOrderDiv>
          </FlexDiv>
          
        </OrderContainer>
      </div>
      )}
    </div>
  );
};
export default Orders;
