import axios from 'axios';

// const API_URL = "http://magoxx-backend-env.eba-qvwsgp38.eu-west-1.elasticbeanstalk.com/api"
const API_URL = "https://admin.platform-magoxx.com/api"

export async function Signup(values) {
    console.log("values",values);
    var data = JSON.stringify({
        name: values.name,
        email: values.email,
        phone: values.phone,
        country: values.country,
        timezone: values.timezone,
        password: values.password,
    })

    var config = {
        method: 'post',
        url: API_URL + '/usersignup',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    }

    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return { error: error.response }
        })
}

export async function UserLogin(name,password) {
    console.log("login",name,password);
    var data = JSON.stringify({
        email: name,
        password: password,
    })

    var config = {
        method: 'post',
        url: API_URL + '/userlogin',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    }

    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return { error: error.response }
        })
}

export async function CheckToken(Token) {

    var config = {
        method: 'get',
        url: API_URL + '/test',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
    }

    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return { error: error.response }
        })
}

export async function PlaceOrder(Token,val) {
    var data = JSON.stringify({
        ...val,
        zipcode: val.zip,
    })

    var config = {
        method: 'post',
        url: API_URL + '/order',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
        data: data,
    }

    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
            return { error: error.response }
        })
}

export async function AddProduct(val) {
    // var data = JSON.stringify({
    //     code: val.code,
    //     quantity: val.quantity,
    //     type: val.type,
    //     size: val.size,
    //     price: val.price,
    // })

    var config = {
        method: 'post',
        url: API_URL + '/addproduct',
        headers: {
            'Content-Type': 'application/json',
        },
        data: val,
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            console.log(error)
            return { error: error.response }
        })
}

export async function GetProduct(Token) {

    var config = {
        method: 'get',
        url: API_URL + '/getproducts',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function GetOrders(Token) {

    var config = {
        method: 'get',
        url: API_URL + '/userorders',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function BasicInfoUpdate(Token,payload) {

    var config = {
        method: 'post',
        url: API_URL + '/basicinfoupdate',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
        data: payload, 
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function ChangeName(Token,payload) {

    var config = {
        method: 'post',
        url: API_URL + '/changename',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
        data: payload, 
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function ChangePassword(Token,payload) {

    var config = {
        method: 'post',
        url: API_URL + '/changepassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
        data: payload, 
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function GetHotItems(Token) {

    var config = {
        method: 'get',
        url: API_URL + '/returnhotitems',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}

export async function GetUserInformation(Token) {

    var config = {
        method: 'post',
        url: API_URL + '/userinformation',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : "Bearer " + Token,
        },
    }

    return axios(config)
        .then(function (response) {
            return response.data    
        })
        .catch(function (error) {
            return { error: error.response }
        })
}
