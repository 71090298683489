export const ORDER_DATA = [
    {
        key: '1',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Refund'
    },
    {
        key: '2',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Complete'
    },
    {
        key: '3',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Canceled'
    },
    {
        key: '4',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Pending'
    },
    {
        key: '5',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Refund'
    },
    {
        key: '6',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Complete'
    },
    {
        key: '7',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Pending'
    },
    {
        key: '8',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Complete'
    },
    {
        key: '9',
        order: {
            serial_no: '#GS-2234',
            name: 'Yonna'
        },
        date_order: {
            date: 'Sunday,Oct 24th,2020',
            time: '08:29 AM'
        },
        arrival_dates: {
            date: 'Oct 29th,2020',
            time: '08:29 AM'
        },
        boards: {
            name: '#Boards',
            number: '3402'
        },
        status: 'Pending'
    }
];

export const STATUS_COLORS = {
    'Refund' : '#ff4242',
    'Canceled' : 'red',
    'Complete' : '#45bf43',
    'Pending' : '#f3c13e',
    'Processing' : '#f3c13e',
};