import NavBar from "../components/Navbar";
import { ProfileOuterDiv, ProfilePageHeading, ProfileAccountSettingsText } from "../styles";
import { useMediaQuery } from 'react-responsive'
import { PROFILE, ACCOUNT_SETTINGS } from "../constants/text";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import Overview from "../components/Overview";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useEffect } from "react";
import { CheckToken } from "../Clients/request";
import { useNavigate } from "react-router-dom";
import BasicInfo from "../components/BasicInfo";
import { Snackbar } from "@mui/material";

const Dropdown_List = {
    "Personal Information":[],
    "Security":[]
};

const Profile = () => {
    const isMobile = useMediaQuery({maxWidth:600});
    const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
    const navigate = useNavigate();
    const [value, setValue] = useState("Personal Information");
    const [dropdownStatus, setDropdownStatus] = useState({"Settings": true, PROFILE: false, BILLING: false});
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const changeStatus = (category) => {
        setDropdownStatus(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    }

    const handleSubItemsClick = (item) => {
        setValue(item);
      };
    

    const TruncatedListItemText = ({ primary }) => (
        <ListItemText
          primary={primary}
          primaryTypographyProps={{
            noWrap: true,
            style: {
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              color: "#202020",
              fontWeight: "bold",
              fontFamily: "Roboto",                          
            },
          }}
        />
      );    

      useEffect(() => {
        const Token = localStorage.getItem("token");
          if(!Token){
              navigate("/login");
          }
          else {
              CheckToken(Token).then((res) => {
                  console.log("res",res)
                  if (res.data) {
                      if (res.data.status == 202) {
                          console.log("good response");
                      } else {
                          console.log("Bad response");
                          navigate("/login")
                      }
                  } else {
                      console.log("error");
                      navigate("/login")
                  }
              })
          }
      }, [])

    return (
        <div style={{height:"100vh",paddingBottom:"50px",overflowY: "auto"}}>
            <NavBar />
            <div className="container d-flex justify-content-center" 
            style={{
                // height: isMaxDesktop ? "84vh" : "80vh",
                marginTop: isMaxDesktop ? "1rem" : ".5rem",
                //   marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
              }}>
                <ProfileOuterDiv>
                    <ProfilePageHeading style={{padding:isMobile? "2rem 0rem" : "2rem 3rem", textAlign:isMobile? 'center' : ''}}>{PROFILE}</ProfilePageHeading>
                    <Grid container spacing={0}>
                    <Grid item xs={12} sm={3}>
                        <div >
                            <ProfileAccountSettingsText style={{marginLeft:"3.1rem"}}>{ACCOUNT_SETTINGS}</ProfileAccountSettingsText>
                        <List
                            sx={{
                            width: "100%",
                            paddingLeft:'2.5rem'
                            }}
                            component="nav"
                        >
                        {Object.entries(Dropdown_List).map(([category, subItems]) => (
                        <React.Fragment key={category}>
                        <ListItemButton
                            onClick={() => {subItems.length > 0 ? changeStatus(category) : handleSubItemsClick(category)}}
                        >
                            <TruncatedListItemText primary={category} />
                            {subItems.length > 0 && (
                                <ExpandLess style={{ transform: dropdownStatus[category] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.5s ease' }} />
                            )}
                        </ListItemButton>
                        <Collapse in={dropdownStatus[category]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            {subItems.map((item) => (
                                <ListItemButton
                                sx={{
                                    pl: 4,
                                    borderRadius: "12px",
                                    backgroundColor:
                                    value === item ? "#eeebf4" : "inherit",
                                }}
                                key={item}
                                onClick={() => handleSubItemsClick(item)}
                                >
                                <TruncatedListItemText primary={item} />
                                </ListItemButton>
                            ))}
                            </List>
                        </Collapse>
                        </React.Fragment>
                        ))}
                        </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                    {value && value === "Personal Information" ? <Overview setOpen={setOpen} setOpenSuccess={setOpenSuccess}/> : 
                    value === "Security"? <BasicInfo setOpen={setOpen} setOpenSuccess={setOpenSuccess}/> :<h1>{value}</h1>}
                    </Grid>
                    </Grid>
                </ProfileOuterDiv>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',  
                    horizontal: 'right' 
                    }}
                    open={open}
                    onClose={()=> setOpen(false)}
                    autoHideDuration={3000}
                    message="An error occured while updating User Info"
                />
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',  
                    horizontal: 'right' 
                    }}
                    open={openSuccess}
                    onClose={()=> setOpenSuccess(false)}
                    autoHideDuration={3000}
                    message="User Info updated Successfully"
                />
            </div>
        </div>
    )
}
export default Profile; 