import { useMediaQuery } from "react-responsive";
import {
  ProductsContainer,
  ProductsText,
  AddOrderBtn,
  FlexDiv,
} from "../../styles";
import { PRODUCTS } from "../../constants/text";
import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import img1 from "../../assets/img1.jpg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string } from "zod";
import { PlaceOrder } from "../../Clients/request";
import { useSelector } from "react-redux";

const registerSchema = object({
  name: string().nonempty("Name is required"),
  last_name: string().nonempty("Last Name is required"),
  phone: string()
    .nonempty("Phone number is required")
    .refine((value) => /^\d+$/.test(value), {
      message: "Phone Number must contain only numbers",
    }),
  email: string().nonempty("Email is required").email("Email is invalid"),
  country: string().nonempty("Country is required"),
  address: string().nonempty("Address is required"),
  zip: string().nonempty("Zip Code is required"),
});

const steps = [ShoppingBasketIcon, LocalShippingIcon, CreditCardIcon];

const Order = () => {
  const location = useLocation();
  const List = location.state?.cartData;
  const isDesktopHeight = useMediaQuery({ minHeight: 870 });
  const isLaptopWidth = useMediaQuery({ minWidth: 580 });
  const isMediumScreen = useMediaQuery({ minWidth: "1000px" });
  const isMediumScreen2 = useMediaQuery({ minWidth: "900px" });
  const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
  const [count, setCount] = useState(new Array(List.length).fill(0));
  const [sum, setSum] = useState(0);
  const [subheading, setSubheading] = useState("Shopping Cart");
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [PersonalInfo, setPersonalInfo] = useState();
  const [Delievery, setDelievery] = useState();
  const dateInputRef = useRef(null);
  const user = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [openFinal, setOpenFinal] = useState(false);
  const [openDeleivery, setOpenDeleivery] = useState(false);
  const [DateConfirmed, setDateConfirmed] = useState(false);
  const [kooiapp, setkooiapp] = useState(false);
  const [sawing, setsawing] = useState(false);
  const [toast, setToast] = useState("");

  useEffect(() => {
    if (activeStep == 0) {
      setSubheading("Shopping Cart");
    } else {
      setSubheading("Shipping Info");
    }
  }, [activeStep]);

  const handleNext = (val) => {
    setPersonalInfo(val);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextDeleivery = (val) => {
    if (DateConfirmed == false) {
      setOpenDeleivery(true);
    } else {
      setPersonalInfo(val);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinal = () => {
    const temp = List.map((item) => {
      return {
        id: item.id,
        quantity: item.order_quantity,
      };
    });
    console.log("item", temp);
    const payload = {
      ...PersonalInfo,
      deliverydate: String(Delievery),
      items: temp,
      kooiaap: kooiapp,
      sawing: sawing,
    };
    console.log("payload", payload);
    PlaceOrder(localStorage.getItem("token"), payload).then((res) => {
      console.log("log", res);
      if (res.data) {
        // console.log("status",res.data);
        if (res.data.status == 200) {
          //localStorage.setItem("token", res.token)
          setToast("Order has been placed successfully");
          setOpenFinal(true);
          navigate("/orders");
          console.log("Good response");
        } else {
          setToast("An error occured while placing the Order");
          console.log("Bad response");
          setOpenFinal(true);
        }
      } else {
        setToast("An error occured while placing the Order");
        setOpenFinal(true);
        console.log("error");
      }
    });
  };

  const handleBack = () => {
    activeStep === 0
      ? navigate("/products")
      : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const total_items = List.reduce((acc, item) => acc + item.order_quantity, 0);

  useEffect(() => {
    if (List) {
      const newSum = List.reduce((acc, item, index) => {
        return acc + item.price * item.order_quantity;
      }, 0);
      setSum(newSum);
    }
  }, [List]);

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: user.name.split(" ")[0],
      last_name: user.name.split(" ")[1],
      phone: user.phone,
      email: user.email,
      country: user.country,
      address: undefined,
      zip: undefined,
    },
  });

  const handleConfirm = () => {
    setDelievery(dateInputRef.current.value);
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 4);
  const minDateString = minDate.toISOString().split("T")[0];

  const handlekooiapp = () => {
    setkooiapp(!kooiapp);
  };
  const handlesawing = () => {
    setsawing(!sawing);
  };

  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <Navbar />
      <div
        className="container d-flex justify-content-center min-vh-80"
        style={{
          overflowY: "auto",
          marginTop: isMaxDesktop ? "1rem" : ".5rem",
          //   marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
        }}
      >
        <ProductsContainer>
          <FlexDiv
            style={{
              justifyContent: "space-between",
              padding: "2rem 3rem 0rem 3rem",
              flexDirection: isLaptopWidth ? "row" : "column",
              alignItems: isLaptopWidth ? "" : "center",
            }}
          >
            <ProductsText>{PRODUCTS}</ProductsText>
            <button
              style={{ border: "none", background: "transparent" }}
              onClick={handleBack}
            >
              <AddOrderBtn>Back</AddOrderBtn>
            </button>
          </FlexDiv>

          <div
            style={{ overflow: "auto", padding: "0.5rem 1.5rem 1.5rem 1.5rem" }}
          >
            <TableContainer
              component={Paper}
              style={{
                minWidth: "800px",
                padding: "30px 20px 30px 20px",
                marginTop: "20px",
                minHeight: "60vh",
                borderRadius: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: "20px" }}
              >
                <Grid item xs={12} md={6}>
                  <h1>{subheading}</h1>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((IconComponent, index) => {
                      return (
                        <Step key={index}>
                          <StepLabel
                            icon={
                              <IconComponent
                                style={{
                                  color:
                                    index === activeStep
                                      ? "blue"
                                      : index < activeStep
                                      ? "green"
                                      : "grey",
                                }}
                              />
                            }
                          />
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
              </Grid>

              {activeStep === 0 ? (
                <>
                <div style={{maxHeight:"35vh",overflow:"auto"}}>
                  <Table aria-label="customized table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "left" }}
                        >
                          Dimension
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "left" }}
                        >
                          Thickness
                        </TableCell>
                        <TableCell
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          Price per m<sup>2</sup>
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "center" }}
                        >
                          Boards for Pallets
                        </TableCell>
                        <TableCell
                          style={{
                            width: "17.5%",
                            textAlign: "center",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {List.map((row, index) => (
                        <TableRow key={row.Id}>
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "50px",
                              }}
                            >
                              {isMediumScreen && (
                                <img
                                  src={row.url}
                                  style={{
                                    width: "50px",
                                    maxHeight: " 50px",
                                    borderRadius: "10px",
                                    marginRight: "20px",
                                  }}
                                />
                              )}
                              {row.type}
                            </div>
                          </TableCell>
                          <TableCell align="left">{row.dimension}mm</TableCell>
                          <TableCell align="center">{row.thickness}mm</TableCell>
                          <TableCell align="center">€ {row.ppsq}</TableCell>
                          <TableCell align="center">{row.btmp}</TableCell>
                          <TableCell align="center">
                            {row.order_quantity}
                          </TableCell>
                          <TableCell align="center">€ {row.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                          marginLeft: "30px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: 600,
                            marginRight: "30px",
                          }}
                        >
                          Kooiapp
                        </p>
                        <Switch onChange={handlekooiapp} />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                          marginLeft: "30px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: 600,
                            marginRight: "30px",
                          }}
                        >
                          Sawing
                        </p>
                        <Switch onChange={handlesawing} />
                      </div>
                    </div>
                    <div style={{ marginRight: "5%", marginTop: "10px" }}>
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <p>
                              Subtotal &nbsp;
                              <span>{total_items} Items</span>
                            </p>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <p>
                              € <span>{sum}</span>
                              /-
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Discount &nbsp;</p>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <p>
                              <span>{user.discount}</span>%
                            </p>
                          </td>
                        </tr>
                        {kooiapp && (
                          <tr>
                            <td>
                              <p>Kooiapp&nbsp;</p>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <p>
                                €<span>80</span>
                                /-
                              </p>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <p>Final Price &nbsp;</p>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <p>
                              €
                              <span>
                                {kooiapp
                                  ? (
                                      sum -
                                      (sum * user.discount) / 100 +
                                      80
                                    ).toFixed(2)
                                  : (sum - (sum * user.discount) / 100).toFixed(
                                      2
                                    )}
                              </span>
                              /-
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "7%",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        padding: 1,
                        paddingX: 4,
                        backgroundColor: "#4caf4d",
                        borderRadius: "21px",
                      }}
                      onClick={handleNext}
                    >
                      Volgende
                    </Button>
                  </div>
                  </>
              ) : activeStep === 1 ? (
                <>
                  <div
                    style={{
                      width: isMediumScreen2 ? "80%" : "100%",
                      margin: "0 auto",
                      paddingLeft: isMediumScreen2 ? "50px" : "3px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        marginLeft: "3%",
                        marginBottom: "20px",
                      }}
                    >
                      Requested Delivery Date
                    </h1>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        lg={8}
                        style={{
                          marginBottom: isMediumScreen2 ? "30px" : "0px",
                        }}
                      >
                        <Box component="form" autoComplete="off">
                          <input
                            ref={dateInputRef}
                            type="date"
                            min={minDateString}
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            onChange={handleConfirm}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={5}
                        lg={3}
                        style={{
                          marginBottom: isMediumScreen2 ? "0px" : "40px",
                        }}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          sx={{
                            padding: 1,
                            paddingX: 4,
                            backgroundColor: "#4caf4d",
                            borderRadius: "21px",
                          }}
                          disabled={Delievery == undefined ? true : false}
                          onClick={() => setOpen(true)}
                        >
                          Confirm
                        </Button>
                        <Dialog
                          open={open}
                          onClose={() => setOpen(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Confirm Date"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you want to confirm the delivery date
                              of {dateInputRef?.current?.value}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                setDateConfirmed(true);
                                setOpen(false);
                              }}
                              autoFocus
                            >
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={openDeleivery}
                          onClose={() => setOpenDeleivery(false)}
                          autoHideDuration={3000}
                          message="Please confirm your delievery date first "
                        />
                      </Grid>
                    </Grid>
                    <h1
                      style={{
                        fontWeight: "bold",
                        marginLeft: "3%",
                        marginBottom: "20px",
                      }}
                    >
                      Shipping address{" "}
                    </h1>
                    <Box component="form" noValidate autoComplete="off">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          style={{
                            marginRight: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="[Name]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("name")}
                          />
                          {errors.name && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.name.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          style={{
                            marginLeft: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="[Last Name]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("last_name")}
                          />
                          {errors.last_name && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.last_name.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          style={{
                            marginRight: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="[Phone]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("phone")}
                          />
                          {errors.phone && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.phone.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          style={{
                            marginLeft: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="email"
                            placeholder="[Email]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("email")}
                          />
                          {errors.email && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.email.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          style={{ marginBottom: "20px" }}
                        >
                          <input
                            type="text"
                            placeholder="[Country]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("country")}
                          />
                          {errors.country && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.country.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={7}
                          style={{
                            marginRight: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="[Address]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("address")}
                          />
                          {errors.address && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.address.message}
                            </span>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          style={{
                            marginLeft: isMediumScreen2 ? "20px" : "0px",
                            marginBottom: "20px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="[Zip Code]"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid grey",
                              color: "black",
                              padding: 10,
                            }}
                            {...register("zip")}
                          />
                          {errors.zip && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errors.zip.message}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        padding: 1,
                        paddingX: 4,
                        backgroundColor: "#4caf4d",
                        borderRadius: "21px",
                        marginTop: "10px",
                      }}
                      onClick={handleSubmit(handleNextDeleivery)}
                    >
                      Volgende
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "left" }}
                        >
                          Dimension
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "left" }}
                        >
                          Thickness
                        </TableCell>
                        <TableCell
                          style={{ width: "12.5%", textAlign: "center" }}
                        >
                          Price per m<sup>2</sup>
                        </TableCell>
                        <TableCell
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          Boards for Pallets
                        </TableCell>
                        <TableCell
                          style={{
                            width: "17.5%",
                            textAlign: "center",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {List.map((row, index) => (
                        <TableRow key={row.Id}>
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "50px",
                              }}
                            >
                              {isMediumScreen && (
                                <img
                                  src={row.url}
                                  style={{
                                    width: "50px",
                                    maxHeight: " 50px",
                                    borderRadius: "10px",
                                    marginRight: "20px",
                                  }}
                                />
                              )}
                              {row.type}
                            </div>
                          </TableCell>
                          <TableCell align="left">{row.dimension}</TableCell>
                          <TableCell align="center">{row.thickness}mm</TableCell>
                          <TableCell align="center">€ {row.ppsq}</TableCell>
                          <TableCell align="center">{row.btmp}</TableCell>
                          <TableCell align="center">
                            {row.order_quantity}
                          </TableCell>
                          <TableCell align="center">€ {row.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <table
                    style={{
                      marginLeft: "auto",
                      marginRight: "5%",
                      marginTop: "10px",
                    }}
                  >
                    <tr>
                      <td style={{ width: "170px" }}>
                        <p>
                          Subtotal &nbsp;
                          <span>{total_items} Items</span>
                        </p>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <p>
                          € <span>{sum}</span>
                          /-
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Discount &nbsp;</p>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <p>
                          <span>{user.discount}</span>%
                        </p>
                      </td>
                    </tr>
                    {kooiapp && (
                      <tr>
                        <td>
                          <p>Kooiapp&nbsp;</p>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <p>
                            €<span>80</span>
                            /-
                          </p>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <p>Final Price &nbsp;</p>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <p>
                          €
                          <span>
                            {kooiapp
                              ? (
                                  sum -
                                  (sum * user.discount) / 100 +
                                  80
                                ).toFixed(2)
                              : (sum - (sum * user.discount) / 100).toFixed(2)}
                          </span>
                          /-
                        </p>
                      </td>
                    </tr>
                  </table>

                  <h1
                    style={{
                      fontWeight: "bold",
                      marginLeft: "3%",
                      marginBottom: "20px",
                    }}
                  >
                    Requested Delivery Date
                  </h1>
                  <Box component="form" noValidate autoComplete="off">
                    <input
                      type="date"
                      value={Delievery}
                      disabled
                      style={{
                        width: "50%",
                        height: "40px",
                        borderRadius: "8px",
                        padding: 10,
                        color: "#7a7a7a",
                      }}
                    />
                  </Box>
                  <h1
                    style={{
                      fontWeight: "bold",
                      marginLeft: "3%",
                      marginBottom: "20px",
                      marginTop: "30px",
                    }}
                  >
                    Shipping address
                  </h1>
                  <Box component="form" noValidate autoComplete="off">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                          marginRight: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Name]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("name")}
                        />
                        {errors.name && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.name.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                          marginLeft: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Last Name]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.last_name.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                          marginRight: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Phone]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("phone")}
                        />
                        {errors.phone && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.phone.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                          marginLeft: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="email"
                          disabled
                          placeholder="[Email]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("email")}
                        />
                        {errors.email && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.email.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{ marginBottom: "20px" }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Country]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("country")}
                        />
                        {errors.country && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.country.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        style={{
                          marginRight: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Address]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("address")}
                        />
                        {errors.address && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.address.message}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          marginLeft: isMediumScreen2 ? "20px" : "0px",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="text"
                          disabled
                          placeholder="[Zip Code]"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "8px",
                            color: "#7a7a7a",
                            padding: 10,
                          }}
                          {...register("zip")}
                        />
                        {errors.zip && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors.zip.message}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "7%",
                      marginTop: "50px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        padding: 1,
                        paddingX: 4,
                        backgroundColor: "#4caf4d",
                        borderRadius: "21px",
                      }}
                      onClick={handleFinal}
                    >
                      Bestellen
                    </Button>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={openFinal}
                      onClose={() => setOpenFinal(false)}
                      autoHideDuration={3000}
                      message={toast}
                    />
                  </div>
                </>
              )}
            </TableContainer>
          </div>
        </ProductsContainer>
      </div>
    </div>
  );
};
export default Order;
