import {
  OrderContainer,
  OrdersText,
  FlexDiv,
  AddOrderBtn,
  InnerDashboardDiv,
  PendingOrderText,
  TableHeadText,
  TableTextOne,
  TableTextFour,
  TableTextTwo,
  TableTextThree,
  SortImg,
  GreenLine,
  StyledRangePickerContainer,
} from "../styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useMediaQuery } from "react-responsive";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  NEW_ORDERS,
  PENDING_ORDERS,
  ORDERS,
  ORDER,
  DATE_ORDER,
  ARRIVAL_DATE,
  BOARDS,
  STATUS,
} from "../constants/text";
import Checkbox from "@mui/material/Checkbox";
import Sort from "../assets/sort.png";
import OrderDropdown from "../components/OrderDropdown";
import { ORDER_DATA, STATUS_COLORS } from "../constants/OrdersData";
import ResponsivePagination from "react-responsive-pagination";
import Navbar from "../components/Navbar";
import { CheckToken, GetHotItems, GetOrders, GetProduct } from "../Clients/request";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HOT_PRODUCTS } from "../constants/DashboardData";
import { Button, Grid, LinearProgress, Snackbar } from "@mui/material";
import paper from "../assets/image 1.png"
import { DataGrid } from "@mui/x-data-grid";

const { RangePicker } = DatePicker;

const columns = [
  { 
    field: 'id', 
    headerName: 'Order', 
    width: 150 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextFour>
          {params.row.order_id}
        </TableTextFour>
        <TableTextOne>
          {params.row.name}
        </TableTextOne>
      </div>
    )
  },
  { 
    field: 'updated_at', 
    headerName: 'Date Order', 
    width: 250 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextTwo>
          {new Date(params.row.updated_at).toISOString().slice(0, 10)}
        </TableTextTwo>
        <TableTextThree>
          {new Date(params.row.updated_at).toISOString().slice(11, 19)}
        </TableTextThree>
      </div>
    )
  },
  { 
    field: 'deliverydate', 
    headerName: 'Arrival Order', 
    width: 300 ,
    headerAlign:"center",
    align:"center",
    renderCell : (params) => (
      <div>
        <TableTextTwo>
          {params.row.deliverydate}
        </TableTextTwo>
        <TableTextThree>
          {params.row.deliverydate}
        </TableTextThree>
      </div>
    )
  },
  {
    field: 'products_type',
    headerName: 'Boards',
    width: 300,
    headerAlign:"center",
    align:"center",
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    headerAlign:"center",
    align:"center",
    renderCell: (params) => (
      <div style={{ color: `${STATUS_COLORS[params.row.status]}` }}>
        {params.row.status}
      </div>
    )
  }
];


const Orders = () => {
  // const isDesktopHeight = useMediaQuery({ minHeight: 870 });
  const isLaptopWidth = useMediaQuery({ minWidth: 580 });
  const isMaxDesktop = useMediaQuery({ minWidth: 1080 });
  const isBreaking = useMediaQuery({ minWidth: 770 });
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [userOrders,setUserOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [List,setList] = useState();
  const [quantity, setQuantity] = useState([]);

  const addQuantityToProducts = () => {
    const ret = List ? List.map((product, index) => ({
      ...product,
      order_quantity: quantity ? quantity[index] : 0 ,
    })) : ""
    return ret;
  };

  const filteredList = List ? addQuantityToProducts()?.filter((product) => product.order_quantity > 0) : "";


  const handleSubmitClick = () => {
    if(filteredList.length > 0){
      navigate("/Cart", { state: { cartData: filteredList } });
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    GetHotItems(localStorage.getItem("token")).then((res) => {
        if (res.data) {
            if (res.data.status === "200") {
              const prod = res.topSellingProducts.map((product) => {
                const sizes = product.size.split('x');
                const dimension = sizes.slice(0, 2).join('x');
                const size = sizes[2];
                return {
                  ...product,
                  dimension: dimension,
                  size: size,
                };
              });
              //slice arr to get first 4 elements 
              if(prod.length > 3){
                setList(prod.slice(0,3));
              }
              else {
                setList(prod);
              }
              setQuantity(new Array(prod.length).fill(0));
              setLoading(false)
            } else {
                console.log("Bad response");
            }
        } else {
            console.log("error");
        } 
    })
  }, [])
  

  useEffect(() => {
    const Token = localStorage.getItem("token");
      if(!Token){
          navigate("/login");
      }
      else {
          CheckToken(Token).then((res) => {
              // console.log("res",res)
              if (res.data) {
                  if (res.data.status == 202) {
                      console.log("Token authenticated");
                  } else {
                      console.log("Bad response");
                      navigate("/login")
                  }
              } else {
                  console.log("error");
                  navigate("/login")
              }
          })
          GetOrders(Token).then((res) => {
            console.log("orders",res)
            if (res.data) {
              if (res.data.status === "200") {
                const rows = Object.values(res.order).map((order) => (order) )
                setUserOrders(rows);
              } else {
                  console.log("Bad response");
              }
              setLoading2(false)
          } else {
              console.log("error");
          }
          })
      }
  }, [])

  
  const handleIncrement = (index) => {
    setQuantity((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = prevCounts[index] + 1;
      return newCounts;
    });
  };

  const handleDecrement = (index) => {
    if (quantity[index] > 0) {
      setQuantity((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = prevCounts[index] - 1;
        return newCounts;
      });
    }
  };


  const handleInputChange = (event, index) => {
    const value = parseInt(event.target.value);

    if (!isNaN(value)) {
      setQuantity((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = value;
        return newCounts;
      });
    }
  };

  const Quantity_Button = ({index}) => {
    return (
      <div style={{ width: "80px" ,marginLeft:"auto",marginRight:"10px"}}>
        <div
          style={{
            display: "flex",
            border: "1px solid gray",
            borderRadius: "7px",
            maxWidth: "200px",
            justifyContent: "space-between",
          }}
        >
          <div onClick={() => handleDecrement(index)}>
            <RemoveIcon style={{color:"black"}}/>
          </div>
          <input
            type="text"
            value={quantity[index]}
            onChange={(event) => handleInputChange(event, index)}
            style={{ maxWidth: "30px", textAlign: "center" }}
          />
          <div className="pl-0" onClick={() => handleIncrement(index)}>
            <AddIcon style={{color:"black"}}/>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{height:"100vh",overflowY:"auto"}}>
      <Navbar />
      {loading && loading2 ? (<LinearProgress color="success" style={{width:"100%",marginTop:"5px"}}/>) :(
      <div
        className="container d-flex justify-content-center"
        style={{
          marginTop: isMaxDesktop ? "1rem" : ".5rem",
        //   //   marginBottom: isDesktopHeight ? "1rem" : "1.5rem",
        }}
      >
        <OrderContainer>
          <FlexDiv
            style={{
              justifyContent: "space-between",
              padding: "2rem 3rem 1rem 3rem",
              flexDirection: isBreaking ? "row" : "column",
              alignItems: isBreaking ? "" : "center",
            }}
          >
            <OrdersText>Welcome {user?.name?.split(" ")[0]} - Dashboard</OrdersText>
            <button style={{ border: "none", background: "transparent" }} onClick={() => navigate('/products')}>
              <AddOrderBtn>Order Now</AddOrderBtn>
            </button>
          </FlexDiv>
          <FlexDiv style={{ margin: "0rem 1rem 0rem 1rem" }}>
            <InnerDashboardDiv>
              <FlexDiv
                style={{
                  justifyContent: "space-between",
                  margin: "2rem 1.5rem",
                  flexDirection: isLaptopWidth ? "row" : "column",
                  alignItems: isLaptopWidth ? "" : "center",
                }}
              >
                <PendingOrderText>Last ordered products</PendingOrderText>
                <div>
                  <RangePicker
                    value={dates || value}
                    onCalendarChange={(val) => {
                      console.log(val);
                      setDates(val);
                    }}
                    onChange={(date, dateArray) => {
                      console.log(date);
                      console.log(dateArray);
                      setValue(dateArray);
                    }}
                    changeOnBlur
                    panelRender={(panelNode) => (
                      <StyledRangePickerContainer>
                        {panelNode}
                      </StyledRangePickerContainer>
                    )}
                  />
                </div>
              </FlexDiv>
              <div
                style={{
                  width: "100%",
                  overflow: "auto",
                  padding: "0.5rem 1.5rem 1.5rem 1.5rem",
                }}
              >
                <div >
                  <DataGrid
                    autoHeight
                    rows={userOrders}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5,10,20]}
                  />
                </div>
              </div>
            </InnerDashboardDiv>
          </FlexDiv>
          <div style={{padding:"25px",backgroundColor:"white", border:"1px solid #eceaee", borderRadius:"24px", margin:"20px"}}>
              <PendingOrderText>Hot Products</PendingOrderText>  
              <Grid container justifyContent={isBreaking ? "" : "center"}>
                {List && List.map((prod,index) => (
                  <Grid item key={index} style={{margin:"20px",height:"237px",backgroundColor:"#f4f7f7",borderRadius:"14px",boxShadow:"2px 2px 5px 0px rgba(0, 0, 0, 0.30)"}}>
                    <div style={{height:"140px",width:"220px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"14px 14px 0px 0px",background:"linear-gradient(180deg, rgba(99,99,99,1) 0%, rgba(120,120,120,1) 44%, rgba(147,147,147,1) 62%, rgba(244,247,247,1) 100%)"}}>
                      <img src={prod.url} style={{height:"120px",width:"120px"}}/>
                    </div>
                    <div style={{marginTop:"15px",display:"flex",fontFamily:"Roboto",fontWeight:600,fontSize:"15px",lineHeight:"19.5px"}}>
                      <p style={{marginLeft:"10px"}}>{prod.type}</p>
                      <p style={{marginRight:"10px",marginLeft:"auto"}}>€ &nbsp;{prod.price}</p>
                    </div>
                    <div style={{display:"flex",marginTop:"30px"}}>
                      <div style={{marginLeft:"10px",fontFamily:"Roboto",fontWeight:400,fontSize:"13px",lineHeight:"19.5px",opacity:"50%"}}>
                        <p>{prod.dimension} mm</p>
                      </div>
                      <Quantity_Button index={index}/>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                sx={{
                  padding: 1,
                  paddingX: 4,
                  margin: 2,
                  marginX: 5,
                  minWidth: "100px",
                  backgroundColor: "#4caf4d",
                  borderRadius: "21px",
                }}
                onClick={handleSubmitClick}
              >
                Bestellen
              </Button>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',  
              horizontal: 'right' 
            }}
            open={open}
            onClose={()=> setOpen(false)}
            autoHideDuration={3000}
            message="Please select some quantity of product first"
          />
        </OrderContainer>
      </div> 
      )}
    </div>
  );
};
export default Orders;
