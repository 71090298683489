import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import '../style/Login.css'
import { useNavigate } from "react-router-dom";
import {UserLogin} from "../Clients/request";
import { useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from 'react-redux'
import { update } from '../store/authReducer';
import logo  from "../assets/logo.jpeg"

const loginSchema = object({
    email: string().nonempty("Email is required").email("Email is invalid"),
    password: string()
      .nonempty("Password is required"),
  });

export default function Login() {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [Mismatch, setMismatch] = React.useState("")
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
      } = useForm({
        resolver: zodResolver(loginSchema),
      });


    const onSubmitHandler = (values) => {
        UserLogin(values.email,values.password).then((res) => {
                if (res.data) {
                // console.log("status",res.data);
                    if (res.data.status == 200) {
                        dispatch(update(res.user));
                        localStorage.setItem("token", res.token)
                        navigate("/")
                        console.log("Good response");
                    } else {
                        setMismatch(res.data.logs || "User doesnot exist")
                        console.log("Bad response");
                    }
                } else {
                    console.log("error");
                }
            })

    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >

            <Box
                component={Paper}
                elevation={6}
                square
                sx={{
                    width: '85vw',
                    height: '85vh',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <Grid container component="main" sx={{ height: '100%' }}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={6}
                        sx={{
                            backgroundImage: 'url(bg.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            display: { xs: 'none', sm: 'block' },
                        }}
                        component={Paper}
                        elevation={6}
                        square
                    >
                        <Box
                            sx={{
                                my: 6,
                                mx: '50px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <h5 className='left_heading_login'>Welkom bij</h5>
                            <h5 className='left_heading_login2'>MAGOXX</h5>
                            <p className='left_subheading_login'>Brandwerend en duurzaam</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                mx: '30px',
                                [theme.breakpoints.up('sm')]: {
                                    mx: '80px',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                                height:"100%",
                                justifyContent:"center",
                            }}
                        >
                            <img src={logo} alt="logo"/>
                            <h5 className='Login'>
                                Login
                            </h5>
                            <p className='subheading' style={{lineHeight:"20px"}}>
                                Welcome Back <br/>Enter your credentials below to Begin
                            </p>
                            <Box component="form" noValidate onSubmit={handleSubmit(onSubmitHandler)} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="EMAIL"
                                    name="email"
                                    variant='standard'
                                    InputLabelProps={{
                                        sx: {
                                          fontSize: '16px', 
                                          fontFamily: "Roboto",
                                        },
                                      }}
                                      inputProps={{
                                        style: {
                                          padding: '1px 9px',
                                          margin: 2,
                                        },
                                      }}
                                    error={!!errors["email"]}
                                    helperText={errors["email"] ? errors["email"].message : ""}
                                    {...register("email")}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="PASSWORD"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    variant='standard'
                                    InputLabelProps={{
                                        sx: {
                                          fontSize: '16px', 
                                          fontFamily: "Roboto",
                                        },
                                      }}
                                      inputProps={{
                                        style: {
                                          padding: '1px 9px',
                                          margin: 2,
                                        },
                                      }}
                                      error={!!errors["password"]}
                                      helperText={errors["password"] ? errors["password"].message : ""}
                                      {...register("password")}
                                />

                                {Mismatch && (<p style={{color:"red",paddingTop:"15px"}}>{Mismatch}</p>)}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: Mismatch? 3 : 8, mb: 2,pt: 1, backgroundColor: "#4caf4d", borderRadius: "21px" }}
                                    className='login_btn'
                                >
                                    Sign In
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>

    )
}
