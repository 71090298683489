export const DASBOARD = "Dashboard";
export const ORDERS = "Orders";
export const REPORTS = "Reports";
export const PRODUCTS = "Products";
export const HELP = "Help";
export const MY_PROFILE = "Profile";
export const NOTIFICATIONS = "Notifications";
export const CLOSE = "Close";
export const NEW_ORDERS = "+ New Order";
export const PENDING_ORDERS = "Pending Orders";
export const ORDER = "Order";
export const DATE_ORDER = "Date Order";
export const ARRIVAL_DATE = "Arrival Date";
export const BOARDS = "#Boards";
export const STATUS = "Status";
export const DASHBOARD = "Dashboard";
export const MAGOXXBOARDS = "MagoxxBoards";
export const LAST_ORDER_PRODUCTS = "Last ordered products";
export const PROFILE = "Profile";
export const ACCOUNT_SETTINGS = "Account Settings";
export const SETTINGS = "Settings";
export const OVERVIEW = 'Overview';
export const BILLING = 'Billing';