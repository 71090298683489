import { FlexDiv, AccountSettingsOption, ProfileInnerDiv, ProfileInnerDivHeading, ProfileInnerDivLine, ProfileInnerImgDiv, InnerGrayDiv } from "../styles";
import cornucopia_landing_icon from "../assets/cornucopia_landing_icon.png";
import { OVERVIEW } from "../constants/text";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from 'react-redux'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ChangeName, ChangePassword, CheckToken } from "../Clients/request";
import { update_name } from "../store/authReducer";
import { useEffect } from "react";

const ValidationSchema = object({
    name: string().nonempty("name is required"),
    oldpassword: string().nonempty("old password is required"),
    password: string()
        .nonempty("Enter new password"),
});

const BasicInfo = ({setOpen,setOpenSuccess}) => {
    const isDesktop = useMediaQuery({minWidth:990});
    const isBreaking = useMediaQuery({minWidth:770});
    const user = useSelector((state) => state.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors, isSubmitSuccessful,isDirty },
        reset,
        handleSubmit,
      } = useForm({
        resolver: zodResolver(ValidationSchema),
        defaultValues:{
            name : user.name,
        }
    });

    const handleSave = (val) => {
        var check = true;
        const payload = {oldpassword :val.oldpassword , password: val.password}
        ChangePassword(localStorage.getItem("token"),payload).then((res) => {
            if (res.data) {
                if (res.data.status == 202) {
                    check = true
                } else {
                    check = false
                    setOpen(true)
                }
            } else {
                setOpen(true)
            }
        })
        setOpenSuccess(check)
    };

    useEffect(() => {
        const Token = localStorage.getItem("token");
          if(!Token){
              navigate("/login");
          }
          else {
              CheckToken(Token).then((res) => {
                  console.log("res",res)
                  if (res.data) {
                      if (res.data.status == 202) {
                          console.log("good response");
                      } else {
                          console.log("Bad response");
                          navigate("/login")
                      }
                  } else {
                      console.log("error");
                      navigate("/login")
                  }
              })
          }
      }, [])

    return(
        <ProfileInnerDiv style={{margin: '0rem 2.5rem'}}>
        <ProfileInnerDivHeading style={{margin:isDesktop?'0rem 2rem':'1rem 0rem',textAlign:isDesktop?'':'center'}}>Basic information</ProfileInnerDivHeading>
        <ProfileInnerDivLine style={{margin: '0rem 1rem'}}/>
        { isBreaking && (
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <FlexDiv style={{gap: '2rem'}}>
                <div style={{margin:'1rem 1.5rem 0.5rem 1.5rem'}}>
                    <AccountSettingsOption style={{ marginTop: '1rem'}}>Old Password</AccountSettingsOption>
                    <AccountSettingsOption style={{ marginTop: '1.6rem'}}>New Password</AccountSettingsOption>
                </div>
                <Box component="form" noValidate style={{maxWidth:"300px"}}>
                    <input 
                        type="password"
                        style={{
                            marginTop:"1.8rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px"
                        }}
                        {...register("oldpassword")}
                    />
                    <input 
                        type="password"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px"
                        }}
                        {...register("password")}
                    />

                </Box>
            </FlexDiv>
            <div>
                <Button 
                    type="submit" 
                    disabled = {!isDirty}
                    sx={{margin:"1rem 1rem 0rem 0rem"}} 
                    onClick={handleSubmit(handleSave)} 
                    variant="contained">Save
                </Button> 
            </div>
        </div>
        )}

        {!isBreaking && (
        <div>
            <Box component="form" noValidate style={{maxWidth:"300px",marginLeft:"20px"}}>
                <AccountSettingsOption style={{ marginTop: '1.6rem'}}>Old Password</AccountSettingsOption>
                <input 
                    type="password"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("oldpassword")}
                />
                <AccountSettingsOption style={{ marginTop: '1.6rem'}}>New Password</AccountSettingsOption>
                <input 
                    type="password"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("password")}
                />

            </Box>
            <div style={{marginTop:"20px",marginLeft:"20px"}}>
                <Button 
                    type="submit" 
                    disabled = {!isDirty}
                    sx={{margin:"1rem 1rem 0rem 0rem"}} 
                    onClick={handleSubmit(handleSave)} 
                    variant="contained">Save
                </Button> 
            </div>
        </div>
        )}
        </ProfileInnerDiv>       
    )
}
export default BasicInfo;