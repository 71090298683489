import { FlexDiv, AccountSettingsOption, ProfileInnerDiv, ProfileInnerDivHeading, ProfileInnerDivLine, ProfileInnerImgDiv, InnerGrayDiv } from "../styles";
import cornucopia_landing_icon from "../assets/cornucopia_landing_icon.png";
import { OVERVIEW } from "../constants/text";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from 'react-redux'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Button, Grid, Snackbar, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { update_profile } from '../store/authReducer';
import { BasicInfoUpdate, ChangeName, CheckToken } from "../Clients/request";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const ValidationSchema = object({
    name: string().nonempty("Name is required"),
    phone: string().nonempty("phone is required"),
    country: string().nonempty("Country is required"),
    timezone: string().nonempty("Time Zone is required"),
    company: string().nonempty("Company name is required"),
    vat: string().nonempty("VAT is required"),
    billing_email: string().nonempty("Billing address is required"),
});


const Overview = ({setOpen,setOpenSuccess}) => {
    const isDesktop = useMediaQuery({minWidth:990});
    const user = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitSuccessful,isDirty },
        reset,
        handleSubmit,
      } = useForm({
        resolver: zodResolver(ValidationSchema),
        defaultValues:{
            name: user.name,
            phone : user.phone,
            country: user.country,
            timezone: user.timeZone,
            company: user.company,
            vat: user.vat,
            billing_email: user.billing_email,
        }
    });
    
    const handleSave = (val) => {
        const temp = {...val,timeZone : val.timezone}
        console.log("payload",val);
        dispatch(update_profile(temp));
        BasicInfoUpdate(localStorage.getItem("token"),val).then((res) => {
            if (res.data) {
                if (res.data.status == 200) {
                    location.reload();
                    setOpenSuccess(true);
                } else {
                    setOpen(true)
                }
            } else {
                setOpen(true)
            }
        })
    };

    useEffect(() => {
        const Token = localStorage.getItem("token");
          if(!Token){
              navigate("/login");
          }
          else {
              CheckToken(Token).then((res) => {
                  if (res.data) {
                      if (res.data.status == 202) {
                          console.log("good response");
                      } else {
                          console.log("Bad response");
                          navigate("/login")
                      }
                  } else {
                      console.log("error");
                      navigate("/login")
                  }
              })
          }
      }, [])
      
    return(
        <ProfileInnerDiv style={{margin: '0rem 2.5rem'}}>
        <ProfileInnerDivHeading style={{margin:isDesktop?'0rem 2rem':'1rem 0rem',textAlign:isDesktop?'':'center'}}>{OVERVIEW}</ProfileInnerDivHeading>
        <ProfileInnerDivLine style={{margin: '0rem 1rem'}}/>
        {isDesktop &&
        <>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <FlexDiv>
                <ProfileInnerImgDiv style={{margin: '1rem 1rem 1rem 1.5rem'}}>
                    <span style={{height:"90%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"30px",color:"white"}}>{user && user.name.includes(" ") ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}` : user.name[0]}</span>
                </ProfileInnerImgDiv>
                <div style={{marginTop:'2rem'}}>
                    <FlexDiv>
                        <AccountSettingsOption>{user.name}</AccountSettingsOption>
                    </FlexDiv>
                    <h5 style={{fontSize: '13px', color: '#898989'}}>{user.email}</h5>
                </div>
            </FlexDiv>
            <div style={{marginTop: '2rem', marginRight: '1.5rem'}}>
                <AccountSettingsOption style={{textAlign: 'right'}}>{user.discount}% Discount</AccountSettingsOption>
                <h5 style={{fontSize: '13px', color: '#898989' , textAlign: 'right'}}>User Custom Discount</h5>
            </div>
        </FlexDiv>
        <ProfileInnerDivLine style={{margin: '0rem 1rem'}}/>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <FlexDiv style={{gap: '2rem'}}>
                <div style={{margin:'1rem 1.5rem 0.5rem 1.5rem'}}>
                    <AccountSettingsOption style={{marginTop: '1rem'}}>Full Name</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>Contact Phone</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>Country</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>Time Zone</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>Company</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>VAT</AccountSettingsOption>
                    <AccountSettingsOption style={{marginTop: '1.7rem'}}>Billing Address</AccountSettingsOption>
                </div>
                <Box component="form" noValidate style={{maxWidth:"300px"}}>
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.5rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",

                        }}
                        {...register("name")}
                        error={!!errors["name"]}
                        helperText={errors["name"] ? errors["name"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.5rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("phone")}
                        error={!!errors["phone"]}
                        helperText={errors["phone"] ? errors["phone"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("country")}
                        error={!!errors["country"]}
                        helperText={errors["country"] ? errors["country"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("timezone")}
                        error={!!errors["timezone"]}
                        helperText={errors["timezone"] ? errors["timezone"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("company")}
                        error={!!errors["company"]}
                        helperText={errors["company"] ? errors["company"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("vat")}
                        error={!!errors["vat"]}
                        helperText={errors["vat"] ? errors["vat"].message : ""}
                    />
                    <input 
                        type="text"
                        style={{
                            marginTop:"1.25rem",
                            borderRadius: "8px",
                            border: "1px solid grey",
                            color: "black",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                        }}
                        {...register("billing_email")}
                        error={!!errors["billing_email"]}
                        helperText={errors["billing_email"] ? errors["billing_email"].message : ""}
                    />

                </Box>
            </FlexDiv>
            <div>
                <Button 
                    type="submit" 
                    disabled = {!isDirty}
                    sx={{margin:"1rem 1rem 0rem 0rem"}} 
                    onClick={handleSubmit(handleSave)} 
                    variant="contained">Save
                </Button> 
            </div>
        </div>
        </>
        }
        {!isDesktop && 
        <>
        <FlexDiv style={{alignItems:'center', justifyContent:'center', flexDirection:'column',gap:'0.3rem'}}>
            <ProfileInnerImgDiv style={{margin:'0.5rem 0rem'}}>
            <span style={{height:"90%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"30px",color:"white"}}>{user && user.name.includes(" ") ? `${user.name.split(" ")[0][0]}${user.name.split(" ")[1][0]}` : user.name[0]}</span>
            </ProfileInnerImgDiv>
            <FlexDiv>
                <AccountSettingsOption>{user.name}</AccountSettingsOption>
            </FlexDiv>
            <h5 style={{fontSize: '13px', color: '#898989'}}>{user.email}</h5>
            <AccountSettingsOption style={{textAlign: 'right'}}>{user.discount}% Discount</AccountSettingsOption>
            <h5 style={{fontSize: '13px', color: '#898989' , textAlign: 'right',marginBottom:"5px"}}>User Custom Discount</h5>
        </FlexDiv>
        <ProfileInnerDivLine style={{margin: '0rem 1rem'}}/>
            <Box component="form" noValidate style={{maxWidth:"600px",marginLeft:"20px"}}>
                <AccountSettingsOption style={{marginTop: '1rem'}}>Full Name</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("name")}
                    error={!!errors["name"]}
                    helperText={errors["name"] ? errors["name"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1rem'}}>Contact Phone</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("phone")}
                    error={!!errors["phone"]}
                    helperText={errors["phone"] ? errors["phone"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1.6rem'}}>Country</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("country")}
                    error={!!errors["country"]}
                    helperText={errors["country"] ? errors["country"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1.6rem'}}>Time Zone</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("timezone")}
                    error={!!errors["timezone"]}
                    helperText={errors["timezone"] ? errors["timezone"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1.6rem'}}>Company</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("company")}
                    error={!!errors["company"]}
                    helperText={errors["company"] ? errors["company"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1.6rem'}}>VAT</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("vat")}
                    error={!!errors["vat"]}
                    helperText={errors["vat"] ? errors["vat"].message : ""}
                />
                <AccountSettingsOption style={{marginTop: '1.6rem'}}>Billing Address</AccountSettingsOption>
                <input 
                    type="text"
                    style={{
                        marginTop:".5rem",
                        borderRadius: "8px",
                        border: "1px solid grey",
                        color: "black",
                        paddingLeft:"10px",
                        width:"70%",
                    }}
                    {...register("billing_email")}
                    error={!!errors["billing_email"]}
                    helperText={errors["billing_email"] ? errors["billing_email"].message : ""}
                />

            </Box>
            <div style={{marginTop:"20px",marginLeft:"20px"}}>
                <Button 
                    type="submit" 
                    disabled = {!isDirty}
                    sx={{margin:"1rem 1rem 0rem 0rem"}} 
                    onClick={handleSubmit(handleSave)} 
                    variant="contained">Save
                </Button> 
            </div>
        </>
        }
        </ProfileInnerDiv>        
    )
}
export default Overview;


