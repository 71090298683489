import styled from "styled-components";

export const NavigationBar = styled.nav`
  display: flex;
  align-items: center;
  position: sticky;
  top: -5px;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  background-color: #fff;
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
`;
export const NavText = styled.div`
  font-size: 14px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
  padding-top: 0.3rem;
`;
export const FlexDiv = styled.div`
  display: flex;
`;
export const NavButtons = styled.button`
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.4);
  }
`;
export const Sidebar = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transition: width 0.5s ease;
`;
export const SidebarButtons = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.5);
  }
`;
export const SidebarText = styled.div`
  font-size: 14px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
  border-bottom: 0.2rem solid #009580;
  margin-top: 50px;
`;
export const TableContainer = styled.div`
  overflow-x: auto;
  padding: 20px;
  width: 1140px;
  height: 852px;
  border-radius: 32px;
  background-color: #f4f7f7;
`;
export const Table = styled.table`
  width: 1000px;
  border-collapse: collapse;
`;
export const TH = styled.th`
  border: 1px solid #e9ecff;
  padding: 8px;
  text-align: left;
`;
export const TD = styled.td`
  border: 1px solid #e9ecff;
  padding: 8px;
  text-align: left;
`;
export const OrderContainer = styled.div`
  width: 100%;
  border-radius: 32px;
  background-color: #f4f7f7;
`;
export const OrdersText = styled.div`
  font-size: 36px;
  line-height: 48px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const AddOrderBtn = styled.div`
  width: 207px;
  height: 54px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #eceaee;
  font-size: 20px;
  line-height: 48px;
  color: #202020;
  font-weight: 500;
  font-family: Roboto;
  text-align: center;
  &:hover {
    transform: scale(1.2);
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(-1px);
  }
  &:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
`;
export const InnerOrderDiv = styled.div`
  width: 100%;
  min-height: 63vh;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #eceaee;
`;

export const InnerDashboardDiv = styled.div`
  width: 100%;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #eceaee;
`;
export const PendingOrderText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const TableHeadText = styled.div`
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  font-family: Roboto;
`;
export const TableTextOne = styled.div`
  font-size: 13px;
  color: #262626;
  font-weight: 600;
  font-family: Roboto;
`;
export const TableTextTwo = styled.div`
  font-size: 11px;
  font-weight: 600;
  font-family: Roboto;
`;
export const TableTextThree = styled.div`
  font-size: 10px;
  color: #262626;
  font-weight: 400;
  font-family: Roboto;
`;
export const TableTextFour = styled.div`
  font-size: 11px;
  color: #1362fc;
  font-weight: 400;
  font-family: Roboto;
`;
export const SortImg = styled.img`
  width: 7px;
  height: 8px;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: transform 0.5s ease;
`;
export const GreenLine = styled.div`
  height: 2px;
  background-color: #009580;
  margin-left: 1.5rem;
  width: 20%;
`;
export const StyledRangePickerContainer = styled.div`
  @media (max-width: 576px) {
    .ant-picker-panels {
      flex-direction: column !important;
    }
  }
`;
export const DashboardContainer = styled.div`
  width: 100%;
  border-radius: 32px;
  background-color: #f4f7f7;
`;
export const MagoxxBoardDiv = styled.div`
  width: 207px;
  height: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #eceaee;
  font-size: 20px;
  line-height: 40px;
  color: #202020;
  font-weight: 500;
  font-family: Roboto;
  text-align: center;
`;
export const DashboardText = styled.div`
  font-size: 38px;
  line-height: 40px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const InnerWhiteDiv = styled.div`
  width: 100%;
  min-height: 60vh;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #eceaee;
`;
export const LastOrderText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const LastOrderProducts = styled.div`
  height: 400px;
  border-radius: 14px;
  background-color: #f4f7f7;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;
export const ProductsContainer = styled.div`
  width: 100%;
  border-radius: 32px;
  background-color: #f4f7f7;
  `;
export const ProductsText = styled.div`
  font-size: 36px;
  line-height: 48px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const ProductCardHeader = styled.div`
  font-size: 26px;
  margin-top: 1rem;
  line-height: 24px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
  display: flex;
  justify-content: center;
`;
export const ProductCardDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  margin: 1.5rem 1.5rem 1rem 1.5rem;
`;
export const ProductCardPrice = styled.div`
  font-size: 20px;
  margin-top: 1rem;
  line-height: 24px;
  font-weight: bold;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  color: #c4c4c4;
`;
export const ProfileOuterDiv = styled.div`
  width: 1140px;
  height: 100%;
  padding-bottom: 2rem;
  border-radius: 32px;
  background-color: #f4f7f7;
`;
export const ProfilePageHeading = styled.h1`
  font-size: 36px;
  line-height: 48px;
  color: #202020;
  font-weight: 500;
  font-family: Roboto;
`;
export const ProfileAccountSettingsText = styled.h2`
  font-size: 16px;
  line-height: 21px;
  color: #202020;
  font-weight: 600;
  font-family: Roboto;
`;
export const AccountSettingsOption = styled.h3`
  font-size: 16px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
`;
export const AccountSettingsOptionDiv = styled.div`
  font-size: 14px;
  color: #202020;
  font-weight: bold;
  font-family: Roboto;
  height: 36px;
  border-radius: 12px;
  background-color: #eeebf4;
`;
export const ProfileInnerDiv = styled.div`
  padding: 1rem 0rem;
  border-radius: 12px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  background-color: #ffffff;
`;
export const ProfileInnerDivHeading = styled.h1`
  font-size: 32px;
  line-height: 41px;
  color: #202020;
  font-weight: 500;
  font-family: Roboto;
`;
export const ProfileInnerDivLine = styled.div`
  height: 1px;
  background-color: #dcdcdc;
`;
export const ProfileInnerImgDiv = styled.div`
  width: 64px;
  height: 64px;
  color: white;
  border-radius: 32px;
  background-color: green;
  border: 1px solid #ececec;
`;
export const InnerGrayDiv = styled.div`
  padding-bottom: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin: 1.5rem 1rem;
`;
export const ProfileBtn = styled.button`
  &:hover {
    background-color: #ebeded;
  }
`;
