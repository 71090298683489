import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: undefined,
    name: undefined,
    email: undefined,
    country: undefined,
    phone: undefined,
    timeZone: undefined,
    discount: undefined,
    company : undefined,
    vat : undefined,
    billing_email : undefined,
    email_verified_at: null,
    created_at: undefined,
    updated_at: undefined,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      update: (state,action) => {
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.country = action.payload.country;
        state.phone = action.payload.phone;
        state.timeZone = action.payload.timeZone;
        state.discount = action.payload.discount;
        state.company =  action.payload.company;
        state.vat = action.payload.vat;
        state.billing_email = action.payload.billing_email;
        state.email_verified_at = action.payload.email_verified_at;
        state.created_at = action.payload.created_at;
        state.updated_at = action.payload.updated_at;
      },
      update_profile: (state,action) => {
        state.id = state.id;
        state.name = action.payload.name;
        state.email = state.email;
        state.discount = state.discount;
        state.email_verified_at = state.email_verified_at;
        state.created_at = state.created_at;
        state.updated_at = state.updated_at;
        state.country = action.payload.country;
        state.phone = action.payload.phone;
        state.timeZone = action.payload.timeZone;
        state.company =  action.payload.company;
        state.vat = action.payload.vat;
        state.billing_email = action.payload.billing_email;
      },
      update_name: (state,action) => {
        state.id = state.id;
        state.name = action.payload.name;
        state.email = state.email;
        state.country = state.country;
        state.phone = state.phone;
        state.timeZone = state.timeZone;
        state.discount = state.discount;
        state.company =  state.company;
        state.vat = state.vat;
        state.billing_email = state.billing_email;
        state.email_verified_at = state.email_verified_at;
        state.created_at = state.created_at;
        state.updated_at = state.updated_at;
      },
    },
  })

export const { update, update_profile, update_name } = authSlice.actions

export default authSlice.reducer;

